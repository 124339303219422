




import Vue from "vue";
import Cookies from "js-cookie";

export default Vue.extend({
  name: "loginerr-view",
  mounted() {
    Cookies.set("loginerr", "true");
    this.$router.push("/");
  },
  methods: {},
});
